import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Joe Leung | Lead Product Manager', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  name: "I'm Joe Leung",
  role: 'A Product Leader',
  subtitle: `In today's tech-enabled world - few businesses can achieve their full potential without tech solutions and no digital product can achieve hockey-stick success without an adaptive operations framework. 
  
  I specialize building outcome driven product teams that tackle business objectives using digital solutions. Whether that's putting a focus on the digital product or leveraging tech to improve operational inefficiencies, my vast knowledge and background has given me a solid foundation to tackle all sorts of opportunities.`,
};

// ABOUT DATA
export const aboutData = {
  img: 'project.jpg',
  sectionTitle1: 'SaaS Development',
  sectionContent1:
    "I specialize in building product at a fast pace where we constantly innovate, release, learn, adjust and iterate the product to meet our users' needs.",
  sectionTitle2: 'UI/UX Design',
  sectionContent2:
    'User interfaces need to be intuitive and easy to use. I stress simplified UX flows combined with beautiful design to provide the largest impact to the user. Design makes everything possible.',
  sectionTitle3: 'Development Process',
  sectionContent3:
    'Moving fast requires a smooth process. As a Certified ScrumMaster®, I understand the fundamental rules for scrum development and know how to tweak the formula to yield the best results.',
  sectionTitle4: 'Competitive Analysis',
  sectionContent4:
    "It doesn't matter if you're on top or just breaking into the market, you have to know your competition. I keep a close watch on what others in my field are doing so that I'm never caught off guard.",
  sectionTitle5: 'Data Driven',
  sectionContent5:
    'We all have our assumptions but nothing is concrete unless we have the numbers. I base my product decisions on the metrics. Testing is a lifestyle, not a task.',
  sectionTitle6: 'Relationship Management',
  sectionContent6:
    'Building positive relationships has always been a forte of mine. Every successful company has relationships with both their users and also other companies in their industry.',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: 'Ourcade',
    info: 'A community for game developers',
    info2: '',
    url: 'https://ourcade.co',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'project.jpg',
    title: 'Weather App',
    info: 'A simple weather app powered by DarkSky',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to work on something together?',
  btn: "Let's Chat",
  email: 'aguycalledjoe@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/guycalledjoe/',
    },
    {
      id: uuidv1(),
      name: 'instagram',
      url: 'https://www.instagram.com/guycalledjoe/',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/aguycalledjoe/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/aguycalledjoe',
    },
    {
      id: uuidv1(),
      name: 'rocket',
      url: 'https://bootstrapping.tools/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
