import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const {
    sectionTitle1,
    sectionContent1,
    sectionTitle2,
    sectionContent2,
    sectionTitle3,
    sectionContent3,
    sectionTitle4,
    sectionContent4,
    sectionTitle5,
    sectionContent5,
    sectionTitle6,
    sectionContent6,
  } = about;

  const [, setIsDesktop] = useState(false);
  const [, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about" className="section about-wrapper">
      <div className="container py-5">
        <Fade top duration={600} delay={400} distance="30px">
          <h1 className="title has-text-white py-5">My Areas of Expertise</h1>
        </Fade>
        <div className="columns is-multiline">
          <Fade bottom duration={800} delay={600} distance="30px">
            <div className="column is-one-third pb-5">
              <span className="icon is-size-1">
                <i className="fa fa-file-code-o" />
              </span>
              <h2 className="title is-size-5 has-text-white pt-2">
                {sectionTitle1 || 'Section Title'}
              </h2>
              <p className="about-wrapper__info-text is-size-6">
                {sectionContent1 || 'Section Content'}
              </p>
            </div>
            <div className="column is-one-third pb-5">
              <span className="icon is-size-1">
                <i className="fa fa-pencil" />
              </span>
              <h2 className="title is-size-5 has-text-white pt-2">
                {sectionTitle2 || 'Section Title'}
              </h2>
              <p className="about-wrapper__info-text is-size-6">
                {sectionContent2 || 'Section Content'}
              </p>
            </div>
            <div className="column is-one-third pb-5">
              <span className="icon is-size-1">
                <i className="fa fa-gears" />
              </span>
              <h2 className="title is-size-5 has-text-white pt-2">
                {sectionTitle3 || 'Section Title'}
              </h2>
              <p className="about-wrapper__info-text is-size-6">
                {sectionContent3 || 'Section Content'}
              </p>
            </div>
          </Fade>
          <Fade bottom duration={700} delay={400} distance="30px">
            <div className="column is-one-third pb-5">
              <span className="icon is-size-1">
                <i className="fa fa-search" />
              </span>
              <h2 className="title is-size-5 has-text-white pt-2">
                {sectionTitle4 || 'Section Title'}
              </h2>
              <p className="about-wrapper__info-text is-size-6">
                {sectionContent4 || 'Section Content'}
              </p>
            </div>
            <div className="column is-one-third pb-5">
              <span className="icon is-size-1">
                <i className="fa fa-bar-chart" />
              </span>
              <h2 className="title is-size-5 has-text-white pt-2">
                {sectionTitle5 || 'Section Title'}
              </h2>
              <p className="about-wrapper__info-text is-size-6">
                {sectionContent5 || 'Section Content'}
              </p>
            </div>
            <div className="column is-one-third pb-5">
              <span className="icon is-size-1">
                <i className="fa fa-heartbeat" />
              </span>
              <h2 className="title is-size-5 has-text-white pt-2">
                {sectionTitle6 || 'Section Title'}
              </h2>
              <p className="about-wrapper__info-text is-size-6">
                {sectionContent6 || 'Section Content'}
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default About;
