/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { name, role, subtitle } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section className="hero is-fullheight is-paddingless">
      <div className="hero-head">
        <nav className="navbar">
          <div className="container">
            <div className="navbar-brand">
              <span
                className="navbar-burger burger {this.state.active ? 'your_className': null"
                data-target="navbarMenuHeroA"
                aria-label="menu"
                aria-expanded="false"
                onClick={() => document.querySelector('.navbar-menu').classList.toggle('is-active')}
              >
                <span />
                <span />
                <span />
              </span>
            </div>
            <div id="navbarMenuHeroA" className="navbar-menu">
              <div className="navbar-end">
                <a href="#about" className="navbar-item">
                  About
                </a>
                <a href="#contact" className="navbar-item">
                  Contact
                </a>
                {/* <a href="/blog" className="navbar-item">
                  Blog
                </a> */}
                {/* <span className="navbar-item">
                  <a href="mailto:joegramming@gmail.com" className="button is-primary is-inverted">
                    <span className="icon">
                      <i className="fa fa-github" />
                    </span>
                    <span>Hire Me</span>
                  </a>
                </span> */}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className="hero-body">
        <div className="container has-text-left">
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <h1 className="title is-size-1">
              <text className="text-color-main"> {name} </text>
            </h1>
            <h2 className="title is-size-3">
              <text className="text-color-main"> {role}</text>
            </h2>
            <h3 className="title is-size-6">{subtitle}</h3>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Header;
