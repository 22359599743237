import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;

  return (
    <section id="contact">
      <div className="container pt-0 pb-5">
        <Fade bottom duration={500} delay={200} distance="30px">
          <div className="contact-wrapper pb-5">
            <p className="contact-wrapper__text">
              {cta || 'Would you like to work with me? Awesome!'}
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : 'https://aguycalledjoe.com'}
            >
              {btn || "Let's Talk"}
            </a>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Contact;
